<template>
  <q-layout>
    <q-page padding>
      <q-toolbar class="q-pr-none">
        <q-toolbar-title class="text-h5">Relatório de Cliques</q-toolbar-title>
        <q-breadcrumbs>
          <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
          <q-breadcrumbs-el label="Relatório de Cliques" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />
      <AdvertiserHeaderClickReports />
      <AdvertiserListClickReports />
    </q-page>
  </q-layout>
</template>

<script>
import AdvertiserHeaderClickReports from '../../components/reports/advertiser/AdvertiserHeaderClickReports';
import AdvertiserListClickReports from '../../components/reports/advertiser/AdvertiserListClickReports';

export default {
  components: { AdvertiserHeaderClickReports, AdvertiserListClickReports },
  name: 'AdvertiserClickReports'
};
</script>
